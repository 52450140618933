/**
 * Sets the font-size and line-height based on the related CSS Variables
 * Accepts an optional $override-line-height argument which
 * allows the user to override the line-height. Useful if you've got
 * two different font-sizes next to each other and you need the
 * line-heights to be the same.
 *
 * Accepts the following for each argument:
 * xsmall
 * small
 * medium
 * large
 * xlarge
 * xxlarge
 */
/**
 * Sets the font-weight based on the related CSS Variables
 *
 * Accepts the following for $font-weight:
 * light
 * regular (default)
 * semibold
 * bold
 * black
 */
/* stylelint-disable */
:root[data-theme='dark'] .emoji-mart,
:root[data-theme='black'] .emoji-mart {
  border-color: var(--darker-grey-colour);
  background: var(--black-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-anchor-icon,
  :root[data-theme='black'] .emoji-mart .emoji-mart-anchor-icon {
    color: var(--dark-grey-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-anchor-selected .emoji-mart-anchor-icon,
  :root[data-theme='black'] .emoji-mart .emoji-mart-anchor-selected .emoji-mart-anchor-icon {
    color: var(--primary-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-bar,
  :root[data-theme='black'] .emoji-mart .emoji-mart-bar {
    border-color: var(--darker-grey-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-anchor-bar,
  :root[data-theme='black'] .emoji-mart .emoji-mart-anchor-bar {
    background: var(--primary-colour) !important; }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-category-label span,
  :root[data-theme='black'] .emoji-mart .emoji-mart-category-label span {
    background: var(--black-colour);
    color: var(--light-grey-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-search input,
  :root[data-theme='black'] .emoji-mart .emoji-mart-search input {
    border-color: var(--off-black-colour);
    background: var(--dark-grey-colour);
    color: var(--lightest-grey-colour); }
    :root[data-theme='dark'] .emoji-mart .emoji-mart-search input::placeholder,
    :root[data-theme='black'] .emoji-mart .emoji-mart-search input::placeholder {
      color: var(--light-grey-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-preview-data,
  :root[data-theme='black'] .emoji-mart .emoji-mart-preview-data {
    color: var(--light-grey-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-skin-swatches,
  :root[data-theme='black'] .emoji-mart .emoji-mart-skin-swatches {
    border-color: var(--off-black-colour);
    background: var(--off-black-colour); }

.container {
  height: 100%;
  background: var(--main-background-colour);
  color: var(--main-copy-colour);
  font-size: var(--font-size-medium) !important;
  line-height: var(--line-height-medium) !important; }
