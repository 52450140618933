// stylelint-disable declaration-no-important

/**
 * Sets the font-weight based on the related CSS Variables
 *
 * Accepts the following for $font-weight:
 * light
 * regular (default)
 * semibold
 * bold
 * black
 */
@mixin font-weight($font-weight: regular) {
  font-weight: var(--font-weight-#{$font-weight}) !important;
}
