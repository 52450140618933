/**
 * Sets the font-size and line-height based on the related CSS Variables
 * Accepts an optional $override-line-height argument which
 * allows the user to override the line-height. Useful if you've got
 * two different font-sizes next to each other and you need the
 * line-heights to be the same.
 *
 * Accepts the following for each argument:
 * xsmall
 * small
 * medium
 * large
 * xlarge
 * xxlarge
 */
/**
 * Sets the font-weight based on the related CSS Variables
 *
 * Accepts the following for $font-weight:
 * light
 * regular (default)
 * semibold
 * bold
 * black
 */
/* stylelint-disable */
:root[data-theme='dark'] .emoji-mart,
:root[data-theme='black'] .emoji-mart {
  border-color: var(--darker-grey-colour);
  background: var(--black-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-anchor-icon,
  :root[data-theme='black'] .emoji-mart .emoji-mart-anchor-icon {
    color: var(--dark-grey-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-anchor-selected .emoji-mart-anchor-icon,
  :root[data-theme='black'] .emoji-mart .emoji-mart-anchor-selected .emoji-mart-anchor-icon {
    color: var(--primary-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-bar,
  :root[data-theme='black'] .emoji-mart .emoji-mart-bar {
    border-color: var(--darker-grey-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-anchor-bar,
  :root[data-theme='black'] .emoji-mart .emoji-mart-anchor-bar {
    background: var(--primary-colour) !important; }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-category-label span,
  :root[data-theme='black'] .emoji-mart .emoji-mart-category-label span {
    background: var(--black-colour);
    color: var(--light-grey-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-search input,
  :root[data-theme='black'] .emoji-mart .emoji-mart-search input {
    border-color: var(--off-black-colour);
    background: var(--dark-grey-colour);
    color: var(--lightest-grey-colour); }
    :root[data-theme='dark'] .emoji-mart .emoji-mart-search input::placeholder,
    :root[data-theme='black'] .emoji-mart .emoji-mart-search input::placeholder {
      color: var(--light-grey-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-preview-data,
  :root[data-theme='black'] .emoji-mart .emoji-mart-preview-data {
    color: var(--light-grey-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-skin-swatches,
  :root[data-theme='black'] .emoji-mart .emoji-mart-skin-swatches {
    border-color: var(--off-black-colour);
    background: var(--off-black-colour); }

.toast-container {
  display: flex;
  position: fixed;
  z-index: 11;
  right: 8px;
  bottom: 8px;
  left: 8px;
  align-items: center;
  justify-content: center;
  transition: 0.25s ease;
  transition-property: right, bottom, left;
  animation-name: fadein;
  animation-duration: 0.25s;
  animation-iteration-count: 1;
  pointer-events: none; }
  @media (max-width: 767.98px) {
    .toast-container {
      right: 0;
      bottom: 0;
      left: 0; } }
  .toast-container.removing {
    animation-name: fadeout; }
  .toast-container .toast {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1016px;
    padding: 16px;
    transition: border-radius 0.25s ease;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    pointer-events: initial; }
    @media (max-width: 767.98px) {
      .toast-container .toast {
        border-radius: 0; } }
    .toast-container .toast .toast-icon {
      margin-right: 16px; }
    .toast-container .toast .toast-message {
      flex: 1 1 auto;
      max-height: 100px;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch; }
    .toast-container .toast .toast-buttons {
      margin-left: 16px; }
    .toast-container .toast .toast-closer {
      margin-left: 16px;
      cursor: pointer; }
    .toast-container .toast.primary {
      background-color: var(--primary-colour);
      color: var(--primary-colour-copy); }
    .toast-container .toast.secondary {
      background-color: var(--secondary-colour);
      color: var(--secondary-colour-copy); }
    .toast-container .toast.positive {
      background-color: var(--positive-colour);
      color: var(--positive-colour-copy); }
    .toast-container .toast.warning {
      background-color: var(--warning-colour);
      color: var(--warning-colour-copy); }
    .toast-container .toast.negative {
      background-color: var(--danger-colour);
      color: var(--danger-colour-copy); }
    .toast-container .toast.action {
      background-color: var(--attention-colour);
      color: var(--attention-colour-copy); }
    .toast-container .toast.development {
      background: repeating-linear-gradient(45deg, var(--toast-development-colour-1), var(--toast-development-colour-1) 20px, var(--toast-development-colour-2) 20px, var(--toast-development-colour-2) 40px); }
      .toast-container .toast.development .toast-message {
        text-shadow: 0 0 3px black; }

@keyframes fadein {
  0% {
    transform: translateY(48px);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes fadeout {
  0% {
    transform: translateY(0);
    opacity: 1; }
  100% {
    transform: translateY(48px);
    opacity: 0; } }
