// stylelint-disable declaration-no-important

/**
 * Sets the font-size and line-height based on the related CSS Variables
 * Accepts an optional $override-line-height argument which
 * allows the user to override the line-height. Useful if you've got
 * two different font-sizes next to each other and you need the
 * line-heights to be the same.
 *
 * Accepts the following for each argument:
 * xsmall
 * small
 * medium
 * large
 * xlarge
 * xxlarge
 */
@mixin font-size($font-size: medium, $override-line-height: false) {
  font-size: var(--font-size-#{$font-size}) !important;

  @if $override-line-height == false {
    line-height: var(--line-height-#{$font-size}) !important;
  } @else {
    line-height: var(--line-height-#{$override-line-height}) !important;
  }
}
