:root[data-theme='dark'],
:root[data-theme='black'] {
  .emoji-mart {
    border-color: var(--darker-grey-colour);
    background: var(--black-colour);

    .emoji-mart-anchor-icon {
      color: var(--dark-grey-colour);
    }

    // stylelint-disable-next-line selector-max-specificity
    .emoji-mart-anchor-selected .emoji-mart-anchor-icon {
      // stylelint-disable-next-line declaration-no-important
      color: var(--primary-colour);
    }

    .emoji-mart-bar {
      border-color: var(--darker-grey-colour);
    }

    .emoji-mart-anchor-bar {
      // stylelint-disable-next-line declaration-no-important
      background: var(--primary-colour) !important;
    }

    .emoji-mart-category-label span {
      background: var(--black-colour);
      color: var(--light-grey-colour);
    }

    .emoji-mart-search input {
      border-color: var(--off-black-colour);
      background: var(--dark-grey-colour);
      color: var(--lightest-grey-colour);

      // stylelint-disable-next-line selector-max-specificity
      &::placeholder {
        color: var(--light-grey-colour);
      }
    }

    .emoji-mart-preview-data {
      color: var(--light-grey-colour);
    }

    .emoji-mart-skin-swatches {
      border-color: var(--off-black-colour);
      background: var(--off-black-colour);
    }
  }
}
