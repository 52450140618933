// Min sizes for each breakpoint
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

$padding: 16px;

$sidebarBreakpoint: sm;
$sidebarWidth: 224px;
$sidebarTransitionTime: 0.25s;
$sidebarEasingFunction: ease;

$actionbarHeight: 40px;
