@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

@import 'src/scss/scss';

:root {
  --font-primary: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  --font-body: var(--font-primary);
  --font-tabular: var(--font-primary);

  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-semibold: 500;
  --font-weight-bold: 700;
  --font-weight-black: 700;

  --natural-bezier: cubic-bezier(0.62, 0.28, 0.23, 0.99);

  /**
   * Nimvelo Colours
   * Should never change, or be used outside of this file
   */
  --flare: #ff465c;
  --sol: #ff8b35;
  --sunflower: #ffdd06;
  --kerman: #95f661;
  --aurora: #00e99f;
  --celeste: #00d5cf;
  --sky-blue: #26a0da;
  --nebula: #9f82e2;
  --tourmaline: #f266ac;
  --sky-at-night: #314755;

  --jet: #252527;
  --charcoal: #39393b;
  --gun-powder: #4d4d4f;
  --storm: #79797b;
  --cgp: #a8a8aa;
  --overcast: #d9d9dc;
  --corona: #f3f3f5;
  --cirrus: #fafafb;

  --flare-copy: #ffffff;
  --sol-copy: #ffffff;
  --sunflower-copy: #4d4d4f;
  --kerman-copy: #ffffff;
  --aurora-copy: #ffffff;
  --celeste-copy: #4d4d4f;
  --sky-blue-copy: #ffffff;
  --nebula-copy: #ffffff;
  --tourmaline-copy: #ffffff;
  --sky-at-night-copy: #ffffff;

  --jet-copy: #ffffff;
  --charcoal-copy: #ffffff;
  --gun-powder-copy: #ffffff;
  --storm-copy: #ffffff;
  --cgp-copy: #4d4d4f;
  --overcast-copy: #4d4d4f;
  --corona-copy: #4d4d4f;
  --cirrus-copy: #4d4d4f;

  /**
   * Brand Grey Colours
   * Should be referenced instead of above colours
   * Eg. From now on reference --black-colour instead of --jet
   */
  --black-colour: var(--jet);
  --off-black-colour: var(--charcoal);
  --darker-grey-colour: var(--gun-powder);
  --dark-grey-colour: var(--storm);
  --light-grey-colour: var(--cgp);
  --lighter-grey-colour: var(--overcast);
  --lightest-grey-colour: var(--corona);
  --off-white-colour: var(--cirrus);
  --white-colour: #ffffff;

  /**
   * Brand Application Colours
   */
  --dark-copy-colour: var(--darker-grey-colour);
  --light-copy-colour: var(--white-colour);
  --primary-colour: var(--sky-blue);
  --primary-colour-copy: var(--sky-blue-copy);
  --secondary-colour: var(--sky-at-night);
  --secondary-colour-copy: var(--sky-at-night-copy);
  --positive-colour: var(--aurora);
  --positive-colour-copy: var(--aurora-copy);
  --warning-colour: var(--sunflower);
  --warning-colour-copy: var(--sunflower-copy);
  --danger-colour: var(--flare);
  --danger-colour-copy: var(--flare-copy);
  --attention-colour: var(--sol);
  --attention-colour-copy: var(--sol-copy);

  /**
   * Theme-Specific Colours
   */
  --main-background-colour: var(--white-colour);
  --main-off-background-colour: var(--lightest-grey-colour);
  --main-copy-colour: var(--darker-grey-colour);
  --main-off-copy-colour: var(--dark-grey-colour);
  --main-outline-colour: var(--lighter-grey-colour);

  /**
   * Component-Specific Colours
   */
  --sidebar-background-colour: var(--darker-grey-colour);
  --sidebar-copy-colour: var(--light-copy-colour);

  --sidebar-inbox-colour: var(--sky-blue);
  --sidebar-inbox-copy-colour: var(--sky-blue-copy);
  --sidebar-feed-colour: var(--sol);
  --sidebar-feed-copy-colour: var(--sol-copy);
  --sidebar-tasks-colour: var(--celeste);
  --sidebar-tasks-copy-colour: var(--celeste-copy);
  --sidebar-lobby-colour: var(--sunflower);
  --sidebar-lobby-copy-colour: var(--sunflower-copy);
  --sidebar-conversations-colour: var(--tourmaline);
  --sidebar-conversations-copy-colour: var(--tourmaline-copy);
  --sidebar-tags-colour: var(--cgp);
  --sidebar-tags-copy-colour: var(--cgp-copy);
  --sidebar-users-online-colour: var(--primary-colour);
  --sidebar-users-offline-colour: var(--light-grey-colour);

  --files-colour: var(--sunflower);
  --participants-colour: var(--tourmaline);

  --action-bar-background-colour: var(--sidebar-background-colour);
  --action-bar-copy-colour: var(--sidebar-copy-colour);

  --inbox-background-colour: var(--off-white-colour);
  --inbox-button-background-colour: var(--lightest-grey-colour);

  --intro-background-colour: var(--lightest-grey-colour);
  --intro-card-background-colour: var(--white-colour);
  --intro-progress-button-colour: var(--primary-colour);
  --intro-progress-button-disabled-colour: var(--lighter-grey-colour);
  --intro-back-button-colour: var(--lighter-grey-colour);

  --message-copy-colour-faded: var(--dark-grey-colour);

  --message-composer-highlight-background-colour: var(--primary-colour);
  --message-composer-highlight-colour: var(--primary-colour-copy);

  --reaction-me-colour: var(--primary-colour);
  --reaction-background-colour: var(--main-background-colour);
  --reaction-add-hover-colour: var(--main-copy-colour);
  --reaction-hover-background-colour: var(--lighter-grey-colour);

  --tag-light-copy-colour: var(--light-copy-colour);
  --tag-dark-copy-colour: var(--copy-colour);

  --toast-development-colour-1: var(--sunflower);
  --toast-development-colour-2: var(--light-grey-colour);

  --task-copy-colour: var(--copy-colour);
  --task-copy-faded-colour: var(--light-grey-colour);
  --task-focus-colour: var(--lightest-grey-colour);
  --task-button-colour: var(--light-grey-colour);
  --task-highlight-colour: var(--primary-colour);
  --task-border-colour: var(--lighter-grey-colour);
  --task-progress-background-colour: var(--lighter-grey-colour);
  --task-progress-foreground-colour: var(--primary-colour);
  --task-progress-text-colour: var(--gun-powder);
  --task-progress-button-colour: var(--lightest-grey-colour);
  --task-progress-button-colour-copy: var(--gun-powder);
  --task-progress-stroke-colour: var(--lightest-grey-colour);

  --accordion-highlight-colour: var(--main-off-background-colour);

  --participants-list-hover-colour: var(--lightest-grey-colour);
  --participants-list-ellipsis-colour: var(--light-grey-colour);

  --status-clear-status-colour: var(--light-grey-colour);

  --fileattachment-delete-colour: var(--danger-colour);

  /**
   * Font sizes and leading taken from Apple's Human Interface Guidelines
   * https://web.archive.org/web/20180130103301/https://developer.apple.com/ios/human-interface-guidelines/visual-design/typography/
   */

  $globalFontSize: small;

  /**
   * I dun sum mafs!
   * A reasonable approximation for calculating
   * line-height (L) from font-size (F) is as follows:
   * L = 1.11F + 2.98
   * Not really important, but might be worth knowing if we
   * need to extrapolate these font-sizes at any point
   */

  @if $globalFontSize == small {
    --font-size-xsmall: 12px;
    --font-size-small: 14px;
    --font-size-medium: 15px;
    --font-size-large: 18px;
    --font-size-xlarge: 20px;
    --font-size-xxlarge: 26px;

    --line-height-xsmall: 16px;
    --line-height-small: 19px;
    --line-height-medium: 20px;
    --line-height-large: 22px;
    --line-height-xlarge: 25px;
    --line-height-xxlarge: 32px;
  }

  @if $globalFontSize == medium {
    --font-size-xsmall: 12px;
    --font-size-small: 14px;
    --font-size-medium: 16px;
    --font-size-large: 19px;
    --font-size-xlarge: 21px;
    --font-size-xxlarge: 27px;

    --line-height-xsmall: 16px;
    --line-height-small: 19px;
    --line-height-medium: 21px;
    --line-height-large: 24px;
    --line-height-xlarge: 26px;
    --line-height-xxlarge: 33px;
  }

  @if $globalFontSize == large {
    --font-size-xsmall: 13px;
    --font-size-small: 16px;
    --font-size-medium: 17px;
    --font-size-large: 20px;
    --font-size-xlarge: 22px;
    --font-size-xxlarge: 28px;

    --line-height-xsmall: 18px;
    --line-height-small: 21px;
    --line-height-medium: 22px;
    --line-height-large: 25px;
    --line-height-xlarge: 28px;
    --line-height-xxlarge: 34px;
  }

  @if $globalFontSize == xlarge {
    --font-size-xsmall: 15px;
    --font-size-small: 18px;
    --font-size-medium: 19px;
    --font-size-large: 22px;
    --font-size-xlarge: 24px;
    --font-size-xxlarge: 30px;

    --line-height-xsmall: 20px;
    --line-height-small: 23px;
    --line-height-medium: 24px;
    --line-height-large: 28px;
    --line-height-xlarge: 30px;
    --line-height-xxlarge: 37px;
  }

  &[data-theme='dark'],
  &[data-theme='black'] {
    --main-background-colour: var(--off-black-colour);
    --main-off-background-colour: var(--black-colour);
    --main-copy-colour: var(--lightest-grey-colour);
    --main-off-copy-colour: var(--light-grey-colour);
    --main-outline-colour: var(--darker-grey-colour);

    --inbox-background-colour: var(--darker-grey-colour);
    --inbox-button-background-colour: var(--darkest-grey-colour);

    --intro-background-colour: var(--black-colour);
    --intro-card-background-colour: var(--off-black-colour);
    --intro-progress-button-colour: var(--primary-colour);
    --intro-progress-button-disabled-colour: var(--darker-grey-colour);
    --intro-back-button-colour: var(--darker-grey-colour);

    --sidebar-background-colour: var(--black-colour);
    --sidebar-copy-colour: var(--light-copy-colour);

    --task-copy-colour: var(--lightest-grey-colour);
    --task-copy-faded-colour: var(--dark-grey-colour);
    --task-focus-colour: var(--darker-grey-colour);
    --task-button-colour: var(--dark-grey-colour);
    --task-highlight-colour: var(--primary-colour);
    --task-border-colour: var(--light-grey-colour);
    --task-info-colour: var(--light-grey-colour);
    --task-progress-background-colour: var(--dark-grey-colour);
    --task-progress-text-colour: var(--primary-colour-copy);
    --task-progress-stroke-colour: var(--gun-powder);
    --task-priority-high-colour: var(--sol);
    --task-priority-very-high-colour: var(--flare);

    --accordion-highlight-colour: var(--main-outline-colour);

    --message-copy-colour-faded: var(--dark-grey-colour);

    --participants-list-hover-colour: var(--darker-grey-colour);
    --participants-list-ellipsis-colour: var(--dark-grey-colour);

    --reaction-background-colour: var(--main-background-colour);
    --reaction-hover-background-colour: var(--lightest-grey-colour);
    --reaction-add-hover-colour: var(--darker-grey-colour);
  }

  &[data-theme='black'] {
    --main-background-colour: var(--black-colour);
    --main-off-background-colour: var(--off-black-colour);
    --main-copy-colour: var(--lightest-grey-colour);
    --main-outline-colour: var(--off-black-colour);
  }

  // &.color-theme-in-transition,
  // &.color-theme-in-transition *,
  // &.color-theme-in-transition *:before,
  // &.color-theme-in-transition *:after {
  //   stylelint-disable-next-line declaration-no-important
  //   transition: all 750ms !important;
  //   stylelint-disable-next-line declaration-no-important
  //   transition-delay: 0 !important;
  // }
}

/* stylelint-disable */

// Code blocks
:root[data-theme='light'] {
  // GitHub
  .hljs {
    display: block;
    overflow-x: auto;
    padding: 0.5em;
    color: #333;
    background: #f8f8f8;
  }

  .hljs-comment,
  .hljs-quote {
    color: #998;
    font-style: italic;
  }

  .hljs-keyword,
  .hljs-selector-tag,
  .hljs-subst {
    color: #333;
    font-weight: bold;
  }

  .hljs-number,
  .hljs-literal,
  .hljs-variable,
  .hljs-template-variable,
  .hljs-tag .hljs-attr {
    color: #008080;
  }

  .hljs-string,
  .hljs-doctag {
    color: #d14;
  }

  .hljs-title,
  .hljs-section,
  .hljs-selector-id {
    color: #900;
    font-weight: bold;
  }

  .hljs-subst {
    font-weight: normal;
  }

  .hljs-type,
  .hljs-class .hljs-title {
    color: #458;
    font-weight: bold;
  }

  .hljs-tag,
  .hljs-name,
  .hljs-attribute {
    color: #000080;
    font-weight: normal;
  }

  .hljs-regexp,
  .hljs-link {
    color: #009926;
  }

  .hljs-symbol,
  .hljs-bullet {
    color: #990073;
  }

  .hljs-built_in,
  .hljs-builtin-name {
    color: #0086b3;
  }

  .hljs-meta {
    color: #999;
    font-weight: bold;
  }

  .hljs-deletion {
    background: #fdd;
  }

  .hljs-addition {
    background: #dfd;
  }

  .hljs-emphasis {
    font-style: italic;
  }

  .hljs-strong {
    font-weight: bold;
  }
}

:root[data-theme='dark'],
:root[data-theme='black'] {
  // Dracula
  .hljs {
    display: block;
    overflow-x: auto;
    padding: 0.5em;
    background: #282a36;
  }

  .hljs-keyword,
  .hljs-selector-tag,
  .hljs-literal,
  .hljs-section,
  .hljs-link {
    color: #8be9fd;
  }

  .hljs-function .hljs-keyword {
    color: #ff79c6;
  }

  .hljs,
  .hljs-subst {
    color: #f8f8f2;
  }

  .hljs-string,
  .hljs-title,
  .hljs-name,
  .hljs-type,
  .hljs-attribute,
  .hljs-symbol,
  .hljs-bullet,
  .hljs-addition,
  .hljs-variable,
  .hljs-template-tag,
  .hljs-template-variable {
    color: #f1fa8c;
  }

  .hljs-comment,
  .hljs-quote,
  .hljs-deletion,
  .hljs-meta {
    color: #6272a4;
  }

  .hljs-keyword,
  .hljs-selector-tag,
  .hljs-literal,
  .hljs-title,
  .hljs-section,
  .hljs-doctag,
  .hljs-type,
  .hljs-name,
  .hljs-strong {
    font-weight: bold;
  }

  .hljs-emphasis {
    font-style: italic;
  }
}

/* stylelint-enable */
