@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700);
/**
 * Sets the font-size and line-height based on the related CSS Variables
 * Accepts an optional $override-line-height argument which
 * allows the user to override the line-height. Useful if you've got
 * two different font-sizes next to each other and you need the
 * line-heights to be the same.
 *
 * Accepts the following for each argument:
 * xsmall
 * small
 * medium
 * large
 * xlarge
 * xxlarge
 */
/**
 * Sets the font-weight based on the related CSS Variables
 *
 * Accepts the following for $font-weight:
 * light
 * regular (default)
 * semibold
 * bold
 * black
 */
/* stylelint-disable */
:root[data-theme='dark'] .emoji-mart,
:root[data-theme='black'] .emoji-mart {
  border-color: var(--darker-grey-colour);
  background: var(--black-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-anchor-icon,
  :root[data-theme='black'] .emoji-mart .emoji-mart-anchor-icon {
    color: var(--dark-grey-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-anchor-selected .emoji-mart-anchor-icon,
  :root[data-theme='black'] .emoji-mart .emoji-mart-anchor-selected .emoji-mart-anchor-icon {
    color: var(--primary-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-bar,
  :root[data-theme='black'] .emoji-mart .emoji-mart-bar {
    border-color: var(--darker-grey-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-anchor-bar,
  :root[data-theme='black'] .emoji-mart .emoji-mart-anchor-bar {
    background: var(--primary-colour) !important; }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-category-label span,
  :root[data-theme='black'] .emoji-mart .emoji-mart-category-label span {
    background: var(--black-colour);
    color: var(--light-grey-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-search input,
  :root[data-theme='black'] .emoji-mart .emoji-mart-search input {
    border-color: var(--off-black-colour);
    background: var(--dark-grey-colour);
    color: var(--lightest-grey-colour); }
    :root[data-theme='dark'] .emoji-mart .emoji-mart-search input::-webkit-input-placeholder,
    :root[data-theme='black'] .emoji-mart .emoji-mart-search input::-webkit-input-placeholder {
      color: var(--light-grey-colour); }
    :root[data-theme='dark'] .emoji-mart .emoji-mart-search input::-ms-input-placeholder,
    :root[data-theme='black'] .emoji-mart .emoji-mart-search input::-ms-input-placeholder {
      color: var(--light-grey-colour); }
    :root[data-theme='dark'] .emoji-mart .emoji-mart-search input::placeholder,
    :root[data-theme='black'] .emoji-mart .emoji-mart-search input::placeholder {
      color: var(--light-grey-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-preview-data,
  :root[data-theme='black'] .emoji-mart .emoji-mart-preview-data {
    color: var(--light-grey-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-skin-swatches,
  :root[data-theme='black'] .emoji-mart .emoji-mart-skin-swatches {
    border-color: var(--off-black-colour);
    background: var(--off-black-colour); }

.toast-container {
  display: flex;
  position: fixed;
  z-index: 11;
  right: 8px;
  bottom: 8px;
  left: 8px;
  align-items: center;
  justify-content: center;
  transition: 0.25s ease;
  transition-property: right, bottom, left;
  -webkit-animation-name: fadein;
          animation-name: fadein;
  -webkit-animation-duration: 0.25s;
          animation-duration: 0.25s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  pointer-events: none; }
  @media (max-width: 767.98px) {
    .toast-container {
      right: 0;
      bottom: 0;
      left: 0; } }
  .toast-container.removing {
    -webkit-animation-name: fadeout;
            animation-name: fadeout; }
  .toast-container .toast {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1016px;
    padding: 16px;
    transition: border-radius 0.25s ease;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    pointer-events: initial; }
    @media (max-width: 767.98px) {
      .toast-container .toast {
        border-radius: 0; } }
    .toast-container .toast .toast-icon {
      margin-right: 16px; }
    .toast-container .toast .toast-message {
      flex: 1 1 auto;
      max-height: 100px;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch; }
    .toast-container .toast .toast-buttons {
      margin-left: 16px; }
    .toast-container .toast .toast-closer {
      margin-left: 16px;
      cursor: pointer; }
    .toast-container .toast.primary {
      background-color: var(--primary-colour);
      color: var(--primary-colour-copy); }
    .toast-container .toast.secondary {
      background-color: var(--secondary-colour);
      color: var(--secondary-colour-copy); }
    .toast-container .toast.positive {
      background-color: var(--positive-colour);
      color: var(--positive-colour-copy); }
    .toast-container .toast.warning {
      background-color: var(--warning-colour);
      color: var(--warning-colour-copy); }
    .toast-container .toast.negative {
      background-color: var(--danger-colour);
      color: var(--danger-colour-copy); }
    .toast-container .toast.action {
      background-color: var(--attention-colour);
      color: var(--attention-colour-copy); }
    .toast-container .toast.development {
      background: repeating-linear-gradient(45deg, var(--toast-development-colour-1), var(--toast-development-colour-1) 20px, var(--toast-development-colour-2) 20px, var(--toast-development-colour-2) 40px); }
      .toast-container .toast.development .toast-message {
        text-shadow: 0 0 3px black; }

@-webkit-keyframes fadein {
  0% {
    -webkit-transform: translateY(48px);
            transform: translateY(48px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@keyframes fadein {
  0% {
    -webkit-transform: translateY(48px);
            transform: translateY(48px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@-webkit-keyframes fadeout {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateY(48px);
            transform: translateY(48px);
    opacity: 0; } }

@keyframes fadeout {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateY(48px);
            transform: translateY(48px);
    opacity: 0; } }

/**
 * Sets the font-size and line-height based on the related CSS Variables
 * Accepts an optional $override-line-height argument which
 * allows the user to override the line-height. Useful if you've got
 * two different font-sizes next to each other and you need the
 * line-heights to be the same.
 *
 * Accepts the following for each argument:
 * xsmall
 * small
 * medium
 * large
 * xlarge
 * xxlarge
 */
/**
 * Sets the font-weight based on the related CSS Variables
 *
 * Accepts the following for $font-weight:
 * light
 * regular (default)
 * semibold
 * bold
 * black
 */
/* stylelint-disable */
:root[data-theme='dark'] .style_emoji-mart__3fWyl,
:root[data-theme='black'] .style_emoji-mart__3fWyl {
  border-color: var(--darker-grey-colour);
  background: var(--black-colour); }
  :root[data-theme='dark'] .style_emoji-mart__3fWyl .style_emoji-mart-anchor-icon__39s74,
  :root[data-theme='black'] .style_emoji-mart__3fWyl .style_emoji-mart-anchor-icon__39s74 {
    color: var(--dark-grey-colour); }
  :root[data-theme='dark'] .style_emoji-mart__3fWyl .style_emoji-mart-anchor-selected__29bUq .style_emoji-mart-anchor-icon__39s74,
  :root[data-theme='black'] .style_emoji-mart__3fWyl .style_emoji-mart-anchor-selected__29bUq .style_emoji-mart-anchor-icon__39s74 {
    color: var(--primary-colour); }
  :root[data-theme='dark'] .style_emoji-mart__3fWyl .style_emoji-mart-bar__2xRNu,
  :root[data-theme='black'] .style_emoji-mart__3fWyl .style_emoji-mart-bar__2xRNu {
    border-color: var(--darker-grey-colour); }
  :root[data-theme='dark'] .style_emoji-mart__3fWyl .style_emoji-mart-anchor-bar__KZrXH,
  :root[data-theme='black'] .style_emoji-mart__3fWyl .style_emoji-mart-anchor-bar__KZrXH {
    background: var(--primary-colour) !important; }
  :root[data-theme='dark'] .style_emoji-mart__3fWyl .style_emoji-mart-category-label__yj6of span,
  :root[data-theme='black'] .style_emoji-mart__3fWyl .style_emoji-mart-category-label__yj6of span {
    background: var(--black-colour);
    color: var(--light-grey-colour); }
  :root[data-theme='dark'] .style_emoji-mart__3fWyl .style_emoji-mart-search__17ka_ input,
  :root[data-theme='black'] .style_emoji-mart__3fWyl .style_emoji-mart-search__17ka_ input {
    border-color: var(--off-black-colour);
    background: var(--dark-grey-colour);
    color: var(--lightest-grey-colour); }
    :root[data-theme='dark'] .style_emoji-mart__3fWyl .style_emoji-mart-search__17ka_ input::-webkit-input-placeholder,
    :root[data-theme='black'] .style_emoji-mart__3fWyl .style_emoji-mart-search__17ka_ input::-webkit-input-placeholder {
      color: var(--light-grey-colour); }
    :root[data-theme='dark'] .style_emoji-mart__3fWyl .style_emoji-mart-search__17ka_ input::-ms-input-placeholder,
    :root[data-theme='black'] .style_emoji-mart__3fWyl .style_emoji-mart-search__17ka_ input::-ms-input-placeholder {
      color: var(--light-grey-colour); }
    :root[data-theme='dark'] .style_emoji-mart__3fWyl .style_emoji-mart-search__17ka_ input::placeholder,
    :root[data-theme='black'] .style_emoji-mart__3fWyl .style_emoji-mart-search__17ka_ input::placeholder {
      color: var(--light-grey-colour); }
  :root[data-theme='dark'] .style_emoji-mart__3fWyl .style_emoji-mart-preview-data__3pfcY,
  :root[data-theme='black'] .style_emoji-mart__3fWyl .style_emoji-mart-preview-data__3pfcY {
    color: var(--light-grey-colour); }
  :root[data-theme='dark'] .style_emoji-mart__3fWyl .style_emoji-mart-skin-swatches__1It0E,
  :root[data-theme='black'] .style_emoji-mart__3fWyl .style_emoji-mart-skin-swatches__1It0E {
    border-color: var(--off-black-colour);
    background: var(--off-black-colour); }

.style_container__1qBXp {
  height: 100%;
  background: var(--main-background-colour);
  color: var(--main-copy-colour);
  font-size: var(--font-size-medium) !important;
  line-height: var(--line-height-medium) !important; }

/**
 * Sets the font-size and line-height based on the related CSS Variables
 * Accepts an optional $override-line-height argument which
 * allows the user to override the line-height. Useful if you've got
 * two different font-sizes next to each other and you need the
 * line-heights to be the same.
 *
 * Accepts the following for each argument:
 * xsmall
 * small
 * medium
 * large
 * xlarge
 * xxlarge
 */
/**
 * Sets the font-weight based on the related CSS Variables
 *
 * Accepts the following for $font-weight:
 * light
 * regular (default)
 * semibold
 * bold
 * black
 */
/* stylelint-disable */
:root[data-theme='dark'] .emoji-mart,
:root[data-theme='black'] .emoji-mart {
  border-color: #4d4d4f;
  border-color: var(--darker-grey-colour);
  background: #252527;
  background: var(--black-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-anchor-icon,
  :root[data-theme='black'] .emoji-mart .emoji-mart-anchor-icon {
    color: #79797b;
    color: var(--dark-grey-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-anchor-selected .emoji-mart-anchor-icon,
  :root[data-theme='black'] .emoji-mart .emoji-mart-anchor-selected .emoji-mart-anchor-icon {
    color: #26a0da;
    color: var(--primary-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-bar,
  :root[data-theme='black'] .emoji-mart .emoji-mart-bar {
    border-color: #4d4d4f;
    border-color: var(--darker-grey-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-anchor-bar,
  :root[data-theme='black'] .emoji-mart .emoji-mart-anchor-bar {
    background: #26a0da !important;
    background: var(--primary-colour) !important; }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-category-label span,
  :root[data-theme='black'] .emoji-mart .emoji-mart-category-label span {
    background: #252527;
    background: var(--black-colour);
    color: #a8a8aa;
    color: var(--light-grey-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-search input,
  :root[data-theme='black'] .emoji-mart .emoji-mart-search input {
    border-color: #39393b;
    border-color: var(--off-black-colour);
    background: #79797b;
    background: var(--dark-grey-colour);
    color: #f3f3f5;
    color: var(--lightest-grey-colour); }
    :root[data-theme='dark'] .emoji-mart .emoji-mart-search input::-webkit-input-placeholder,
    :root[data-theme='black'] .emoji-mart .emoji-mart-search input::-webkit-input-placeholder {
      color: #a8a8aa;
      color: var(--light-grey-colour); }
    :root[data-theme='dark'] .emoji-mart .emoji-mart-search input::-ms-input-placeholder,
    :root[data-theme='black'] .emoji-mart .emoji-mart-search input::-ms-input-placeholder {
      color: #a8a8aa;
      color: var(--light-grey-colour); }
    :root[data-theme='dark'] .emoji-mart .emoji-mart-search input::placeholder,
    :root[data-theme='black'] .emoji-mart .emoji-mart-search input::placeholder {
      color: #a8a8aa;
      color: var(--light-grey-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-preview-data,
  :root[data-theme='black'] .emoji-mart .emoji-mart-preview-data {
    color: #a8a8aa;
    color: var(--light-grey-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-skin-swatches,
  :root[data-theme='black'] .emoji-mart .emoji-mart-skin-swatches {
    border-color: #39393b;
    border-color: var(--off-black-colour);
    background: #39393b;
    background: var(--off-black-colour); }

:root {
  --font-primary: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  --font-body: var(--font-primary);
  --font-tabular: var(--font-primary);
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-semibold: 500;
  --font-weight-bold: 700;
  --font-weight-black: 700;
  --natural-bezier: cubic-bezier(0.62, 0.28, 0.23, 0.99);
  /**
   * Nimvelo Colours
   * Should never change, or be used outside of this file
   */
  --flare: #ff465c;
  --sol: #ff8b35;
  --sunflower: #ffdd06;
  --kerman: #95f661;
  --aurora: #00e99f;
  --celeste: #00d5cf;
  --sky-blue: #26a0da;
  --nebula: #9f82e2;
  --tourmaline: #f266ac;
  --sky-at-night: #314755;
  --jet: #252527;
  --charcoal: #39393b;
  --gun-powder: #4d4d4f;
  --storm: #79797b;
  --cgp: #a8a8aa;
  --overcast: #d9d9dc;
  --corona: #f3f3f5;
  --cirrus: #fafafb;
  --flare-copy: #ffffff;
  --sol-copy: #ffffff;
  --sunflower-copy: #4d4d4f;
  --kerman-copy: #ffffff;
  --aurora-copy: #ffffff;
  --celeste-copy: #4d4d4f;
  --sky-blue-copy: #ffffff;
  --nebula-copy: #ffffff;
  --tourmaline-copy: #ffffff;
  --sky-at-night-copy: #ffffff;
  --jet-copy: #ffffff;
  --charcoal-copy: #ffffff;
  --gun-powder-copy: #ffffff;
  --storm-copy: #ffffff;
  --cgp-copy: #4d4d4f;
  --overcast-copy: #4d4d4f;
  --corona-copy: #4d4d4f;
  --cirrus-copy: #4d4d4f;
  /**
   * Brand Grey Colours
   * Should be referenced instead of above colours
   * Eg. From now on reference --black-colour instead of --jet
   */
  --black-colour: var(--jet);
  --off-black-colour: var(--charcoal);
  --darker-grey-colour: var(--gun-powder);
  --dark-grey-colour: var(--storm);
  --light-grey-colour: var(--cgp);
  --lighter-grey-colour: var(--overcast);
  --lightest-grey-colour: var(--corona);
  --off-white-colour: var(--cirrus);
  --white-colour: #ffffff;
  /**
   * Brand Application Colours
   */
  --dark-copy-colour: var(--darker-grey-colour);
  --light-copy-colour: var(--white-colour);
  --primary-colour: var(--sky-blue);
  --primary-colour-copy: var(--sky-blue-copy);
  --secondary-colour: var(--sky-at-night);
  --secondary-colour-copy: var(--sky-at-night-copy);
  --positive-colour: var(--aurora);
  --positive-colour-copy: var(--aurora-copy);
  --warning-colour: var(--sunflower);
  --warning-colour-copy: var(--sunflower-copy);
  --danger-colour: var(--flare);
  --danger-colour-copy: var(--flare-copy);
  --attention-colour: var(--sol);
  --attention-colour-copy: var(--sol-copy);
  /**
   * Theme-Specific Colours
   */
  --main-background-colour: var(--white-colour);
  --main-off-background-colour: var(--lightest-grey-colour);
  --main-copy-colour: var(--darker-grey-colour);
  --main-off-copy-colour: var(--dark-grey-colour);
  --main-outline-colour: var(--lighter-grey-colour);
  /**
   * Component-Specific Colours
   */
  --sidebar-background-colour: var(--darker-grey-colour);
  --sidebar-copy-colour: var(--light-copy-colour);
  --sidebar-inbox-colour: var(--sky-blue);
  --sidebar-inbox-copy-colour: var(--sky-blue-copy);
  --sidebar-feed-colour: var(--sol);
  --sidebar-feed-copy-colour: var(--sol-copy);
  --sidebar-tasks-colour: var(--celeste);
  --sidebar-tasks-copy-colour: var(--celeste-copy);
  --sidebar-lobby-colour: var(--sunflower);
  --sidebar-lobby-copy-colour: var(--sunflower-copy);
  --sidebar-conversations-colour: var(--tourmaline);
  --sidebar-conversations-copy-colour: var(--tourmaline-copy);
  --sidebar-tags-colour: var(--cgp);
  --sidebar-tags-copy-colour: var(--cgp-copy);
  --sidebar-users-online-colour: var(--primary-colour);
  --sidebar-users-offline-colour: var(--light-grey-colour);
  --files-colour: var(--sunflower);
  --participants-colour: var(--tourmaline);
  --action-bar-background-colour: var(--sidebar-background-colour);
  --action-bar-copy-colour: var(--sidebar-copy-colour);
  --inbox-background-colour: var(--off-white-colour);
  --inbox-button-background-colour: var(--lightest-grey-colour);
  --intro-background-colour: var(--lightest-grey-colour);
  --intro-card-background-colour: var(--white-colour);
  --intro-progress-button-colour: var(--primary-colour);
  --intro-progress-button-disabled-colour: var(--lighter-grey-colour);
  --intro-back-button-colour: var(--lighter-grey-colour);
  --message-copy-colour-faded: var(--dark-grey-colour);
  --message-composer-highlight-background-colour: var(--primary-colour);
  --message-composer-highlight-colour: var(--primary-colour-copy);
  --reaction-me-colour: var(--primary-colour);
  --reaction-background-colour: var(--main-background-colour);
  --reaction-add-hover-colour: var(--main-copy-colour);
  --reaction-hover-background-colour: var(--lighter-grey-colour);
  --tag-light-copy-colour: var(--light-copy-colour);
  --tag-dark-copy-colour: var(--copy-colour);
  --toast-development-colour-1: var(--sunflower);
  --toast-development-colour-2: var(--light-grey-colour);
  --task-copy-colour: var(--copy-colour);
  --task-copy-faded-colour: var(--light-grey-colour);
  --task-focus-colour: var(--lightest-grey-colour);
  --task-button-colour: var(--light-grey-colour);
  --task-highlight-colour: var(--primary-colour);
  --task-border-colour: var(--lighter-grey-colour);
  --task-progress-background-colour: var(--lighter-grey-colour);
  --task-progress-foreground-colour: var(--primary-colour);
  --task-progress-text-colour: var(--gun-powder);
  --task-progress-button-colour: var(--lightest-grey-colour);
  --task-progress-button-colour-copy: var(--gun-powder);
  --task-progress-stroke-colour: var(--lightest-grey-colour);
  --accordion-highlight-colour: var(--main-off-background-colour);
  --participants-list-hover-colour: var(--lightest-grey-colour);
  --participants-list-ellipsis-colour: var(--light-grey-colour);
  --status-clear-status-colour: var(--light-grey-colour);
  --fileattachment-delete-colour: var(--danger-colour);
  /**
   * Font sizes and leading taken from Apple's Human Interface Guidelines
   * https://web.archive.org/web/20180130103301/https://developer.apple.com/ios/human-interface-guidelines/visual-design/typography/
   */
  /**
   * I dun sum mafs!
   * A reasonable approximation for calculating
   * line-height (L) from font-size (F) is as follows:
   * L = 1.11F + 2.98
   * Not really important, but might be worth knowing if we
   * need to extrapolate these font-sizes at any point
   */
  --font-size-xsmall: 12px;
  --font-size-small: 14px;
  --font-size-medium: 15px;
  --font-size-large: 18px;
  --font-size-xlarge: 20px;
  --font-size-xxlarge: 26px;
  --line-height-xsmall: 16px;
  --line-height-small: 19px;
  --line-height-medium: 20px;
  --line-height-large: 22px;
  --line-height-xlarge: 25px;
  --line-height-xxlarge: 32px; }
  :root[data-theme='dark'], :root[data-theme='black'] {
    --main-background-colour: var(--off-black-colour);
    --main-off-background-colour: var(--black-colour);
    --main-copy-colour: var(--lightest-grey-colour);
    --main-off-copy-colour: var(--light-grey-colour);
    --main-outline-colour: var(--darker-grey-colour);
    --inbox-background-colour: var(--darker-grey-colour);
    --inbox-button-background-colour: var(--darkest-grey-colour);
    --intro-background-colour: var(--black-colour);
    --intro-card-background-colour: var(--off-black-colour);
    --intro-progress-button-colour: var(--primary-colour);
    --intro-progress-button-disabled-colour: var(--darker-grey-colour);
    --intro-back-button-colour: var(--darker-grey-colour);
    --sidebar-background-colour: var(--black-colour);
    --sidebar-copy-colour: var(--light-copy-colour);
    --task-copy-colour: var(--lightest-grey-colour);
    --task-copy-faded-colour: var(--dark-grey-colour);
    --task-focus-colour: var(--darker-grey-colour);
    --task-button-colour: var(--dark-grey-colour);
    --task-highlight-colour: var(--primary-colour);
    --task-border-colour: var(--light-grey-colour);
    --task-info-colour: var(--light-grey-colour);
    --task-progress-background-colour: var(--dark-grey-colour);
    --task-progress-text-colour: var(--primary-colour-copy);
    --task-progress-stroke-colour: var(--gun-powder);
    --task-priority-high-colour: var(--sol);
    --task-priority-very-high-colour: var(--flare);
    --accordion-highlight-colour: var(--main-outline-colour);
    --message-copy-colour-faded: var(--dark-grey-colour);
    --participants-list-hover-colour: var(--darker-grey-colour);
    --participants-list-ellipsis-colour: var(--dark-grey-colour);
    --reaction-background-colour: var(--main-background-colour);
    --reaction-hover-background-colour: var(--lightest-grey-colour);
    --reaction-add-hover-colour: var(--darker-grey-colour); }
  :root[data-theme='black'] {
    --main-background-colour: var(--black-colour);
    --main-off-background-colour: var(--off-black-colour);
    --main-copy-colour: var(--lightest-grey-colour);
    --main-outline-colour: var(--off-black-colour); }

/* stylelint-disable */
:root[data-theme='light'] .hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  color: #333;
  background: #f8f8f8; }

:root[data-theme='light'] .hljs-comment,
:root[data-theme='light'] .hljs-quote {
  color: #998;
  font-style: italic; }

:root[data-theme='light'] .hljs-keyword,
:root[data-theme='light'] .hljs-selector-tag,
:root[data-theme='light'] .hljs-subst {
  color: #333;
  font-weight: bold; }

:root[data-theme='light'] .hljs-number,
:root[data-theme='light'] .hljs-literal,
:root[data-theme='light'] .hljs-variable,
:root[data-theme='light'] .hljs-template-variable,
:root[data-theme='light'] .hljs-tag .hljs-attr {
  color: #008080; }

:root[data-theme='light'] .hljs-string,
:root[data-theme='light'] .hljs-doctag {
  color: #d14; }

:root[data-theme='light'] .hljs-title,
:root[data-theme='light'] .hljs-section,
:root[data-theme='light'] .hljs-selector-id {
  color: #900;
  font-weight: bold; }

:root[data-theme='light'] .hljs-subst {
  font-weight: normal; }

:root[data-theme='light'] .hljs-type,
:root[data-theme='light'] .hljs-class .hljs-title {
  color: #458;
  font-weight: bold; }

:root[data-theme='light'] .hljs-tag,
:root[data-theme='light'] .hljs-name,
:root[data-theme='light'] .hljs-attribute {
  color: #000080;
  font-weight: normal; }

:root[data-theme='light'] .hljs-regexp,
:root[data-theme='light'] .hljs-link {
  color: #009926; }

:root[data-theme='light'] .hljs-symbol,
:root[data-theme='light'] .hljs-bullet {
  color: #990073; }

:root[data-theme='light'] .hljs-built_in,
:root[data-theme='light'] .hljs-builtin-name {
  color: #0086b3; }

:root[data-theme='light'] .hljs-meta {
  color: #999;
  font-weight: bold; }

:root[data-theme='light'] .hljs-deletion {
  background: #fdd; }

:root[data-theme='light'] .hljs-addition {
  background: #dfd; }

:root[data-theme='light'] .hljs-emphasis {
  font-style: italic; }

:root[data-theme='light'] .hljs-strong {
  font-weight: bold; }

:root[data-theme='dark'] .hljs,
:root[data-theme='black'] .hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  background: #282a36; }

:root[data-theme='dark'] .hljs-keyword,
:root[data-theme='dark'] .hljs-selector-tag,
:root[data-theme='dark'] .hljs-literal,
:root[data-theme='dark'] .hljs-section,
:root[data-theme='dark'] .hljs-link,
:root[data-theme='black'] .hljs-keyword,
:root[data-theme='black'] .hljs-selector-tag,
:root[data-theme='black'] .hljs-literal,
:root[data-theme='black'] .hljs-section,
:root[data-theme='black'] .hljs-link {
  color: #8be9fd; }

:root[data-theme='dark'] .hljs-function .hljs-keyword,
:root[data-theme='black'] .hljs-function .hljs-keyword {
  color: #ff79c6; }

:root[data-theme='dark'] .hljs,
:root[data-theme='dark'] .hljs-subst,
:root[data-theme='black'] .hljs,
:root[data-theme='black'] .hljs-subst {
  color: #f8f8f2; }

:root[data-theme='dark'] .hljs-string,
:root[data-theme='dark'] .hljs-title,
:root[data-theme='dark'] .hljs-name,
:root[data-theme='dark'] .hljs-type,
:root[data-theme='dark'] .hljs-attribute,
:root[data-theme='dark'] .hljs-symbol,
:root[data-theme='dark'] .hljs-bullet,
:root[data-theme='dark'] .hljs-addition,
:root[data-theme='dark'] .hljs-variable,
:root[data-theme='dark'] .hljs-template-tag,
:root[data-theme='dark'] .hljs-template-variable,
:root[data-theme='black'] .hljs-string,
:root[data-theme='black'] .hljs-title,
:root[data-theme='black'] .hljs-name,
:root[data-theme='black'] .hljs-type,
:root[data-theme='black'] .hljs-attribute,
:root[data-theme='black'] .hljs-symbol,
:root[data-theme='black'] .hljs-bullet,
:root[data-theme='black'] .hljs-addition,
:root[data-theme='black'] .hljs-variable,
:root[data-theme='black'] .hljs-template-tag,
:root[data-theme='black'] .hljs-template-variable {
  color: #f1fa8c; }

:root[data-theme='dark'] .hljs-comment,
:root[data-theme='dark'] .hljs-quote,
:root[data-theme='dark'] .hljs-deletion,
:root[data-theme='dark'] .hljs-meta,
:root[data-theme='black'] .hljs-comment,
:root[data-theme='black'] .hljs-quote,
:root[data-theme='black'] .hljs-deletion,
:root[data-theme='black'] .hljs-meta {
  color: #6272a4; }

:root[data-theme='dark'] .hljs-keyword,
:root[data-theme='dark'] .hljs-selector-tag,
:root[data-theme='dark'] .hljs-literal,
:root[data-theme='dark'] .hljs-title,
:root[data-theme='dark'] .hljs-section,
:root[data-theme='dark'] .hljs-doctag,
:root[data-theme='dark'] .hljs-type,
:root[data-theme='dark'] .hljs-name,
:root[data-theme='dark'] .hljs-strong,
:root[data-theme='black'] .hljs-keyword,
:root[data-theme='black'] .hljs-selector-tag,
:root[data-theme='black'] .hljs-literal,
:root[data-theme='black'] .hljs-title,
:root[data-theme='black'] .hljs-section,
:root[data-theme='black'] .hljs-doctag,
:root[data-theme='black'] .hljs-type,
:root[data-theme='black'] .hljs-name,
:root[data-theme='black'] .hljs-strong {
  font-weight: bold; }

:root[data-theme='dark'] .hljs-emphasis,
:root[data-theme='black'] .hljs-emphasis {
  font-style: italic; }

/* stylelint-enable */

/**
 * Sets the font-size and line-height based on the related CSS Variables
 * Accepts an optional $override-line-height argument which
 * allows the user to override the line-height. Useful if you've got
 * two different font-sizes next to each other and you need the
 * line-heights to be the same.
 *
 * Accepts the following for each argument:
 * xsmall
 * small
 * medium
 * large
 * xlarge
 * xxlarge
 */
/**
 * Sets the font-weight based on the related CSS Variables
 *
 * Accepts the following for $font-weight:
 * light
 * regular (default)
 * semibold
 * bold
 * black
 */
/* stylelint-disable */
:root[data-theme='dark'] .emoji-mart,
:root[data-theme='black'] .emoji-mart {
  border-color: var(--darker-grey-colour);
  background: var(--black-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-anchor-icon,
  :root[data-theme='black'] .emoji-mart .emoji-mart-anchor-icon {
    color: var(--dark-grey-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-anchor-selected .emoji-mart-anchor-icon,
  :root[data-theme='black'] .emoji-mart .emoji-mart-anchor-selected .emoji-mart-anchor-icon {
    color: var(--primary-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-bar,
  :root[data-theme='black'] .emoji-mart .emoji-mart-bar {
    border-color: var(--darker-grey-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-anchor-bar,
  :root[data-theme='black'] .emoji-mart .emoji-mart-anchor-bar {
    background: var(--primary-colour) !important; }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-category-label span,
  :root[data-theme='black'] .emoji-mart .emoji-mart-category-label span {
    background: var(--black-colour);
    color: var(--light-grey-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-search input,
  :root[data-theme='black'] .emoji-mart .emoji-mart-search input {
    border-color: var(--off-black-colour);
    background: var(--dark-grey-colour);
    color: var(--lightest-grey-colour); }
    :root[data-theme='dark'] .emoji-mart .emoji-mart-search input::-webkit-input-placeholder,
    :root[data-theme='black'] .emoji-mart .emoji-mart-search input::-webkit-input-placeholder {
      color: var(--light-grey-colour); }
    :root[data-theme='dark'] .emoji-mart .emoji-mart-search input::-ms-input-placeholder,
    :root[data-theme='black'] .emoji-mart .emoji-mart-search input::-ms-input-placeholder {
      color: var(--light-grey-colour); }
    :root[data-theme='dark'] .emoji-mart .emoji-mart-search input::placeholder,
    :root[data-theme='black'] .emoji-mart .emoji-mart-search input::placeholder {
      color: var(--light-grey-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-preview-data,
  :root[data-theme='black'] .emoji-mart .emoji-mart-preview-data {
    color: var(--light-grey-colour); }
  :root[data-theme='dark'] .emoji-mart .emoji-mart-skin-swatches,
  :root[data-theme='black'] .emoji-mart .emoji-mart-skin-swatches {
    border-color: var(--off-black-colour);
    background: var(--off-black-colour); }

html {
  box-sizing: border-box;
  height: 100%;
  background-color: var(--main-background-colour);
  font-family: var(--font-primary);
  font-weight: var(--font-weight-regular) !important; }

body {
  height: 100%; }

pre {
  overflow-x: auto; }

#root {
  height: 100%; }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility; }

*,
*:before,
*:after {
  box-sizing: inherit; }

.tabular {
  font-family: var(--font-tabular); }

